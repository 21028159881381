<style>
.btn-gohome{
  width: 60%;
  margin: auto;
  margin-top: 3em;
  margin-bottom: 3em;
  color: #fff;
  background-color: #ff5011;
}
</style>
<template>
    <div id="illness-review" style="min-height:90vh">
        <div class="blue-bg">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-8 text-center pt-5">
                        <h3 class="text-center mb-5">{{$t("paymentConfirm")}}</h3>

                        <div class="thank2you" v-if="Global.tp === 'nb' || Global.tp === 'ren'">
                            <h4 class="text-center h5 pt-2" v-if="Global.quote.productType == Global.enumProductType.PA">
                                {{$t("policyStartPA")}}
                            </h4>
                            <h4 class="text-center h5 pt-2" v-else>
                                <i18n-t tag="div" keypath="policyStart">
                                    <span>
                                        {{policyStartDate.d}}
                                    </span>
                                    <span>
                                        {{policyStartDate.m}}
                                    </span>
                                    <span>
                                        {{policyStartDate.y}}
                                    </span>
                                </i18n-t>
                            </h4>
                        </div>

                        <div class="text-center" style="margin: 3vh auto 0; background-image:url('images/bg-first.png'); background-repeat: no-repeat; background-position: bottom right; min-height:100px;" >
                            <button id="arrow2next" class="btn btn-outline" style="cursor:pointer;" v-if="false">
                                <h2 class="text-default animated2fast bounce2fast">&#8595;</h2>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button class="btn btn-gohome btn-block p-2" data-selenium-name="floating-buynow" 
@click="gobackhome();">
            {{$t("gobackhome")}}
        </button>

        <div class="container" v-if="false && !Global.isAgentTraditionalBroker">
            <div class="row justify-content-center">
                <div class="col-12 col-md-8">
                    <h5 class="text-center pt-5" style="line-height: 1.4;">{{$t("shareExpirience")}}</h5>

                    <div class="card" id="card-rating">
                        <div class="card-body px-md-5">
                            <div class="rating-form mb-4">
                                <h5  class="text-center mb-4">{{$t("feedback")}}</h5>

                                <div class="rating-detail">
                                    <ul>
                                        <li class="overall-star">
                                            <div class="rating-label rating-overall">{{$t("rating.overall")}}</div>
                                            <div class="rating">
                                                <button class="icon" v-bind:class="{'selected' : rating.overall == 5}" @click="rating.overall = 5">★</button>
                                                <button class="icon" v-bind:class="{'selected' : rating.overall >= 4}" @click="rating.overall = 4">★</button>
                                                <button class="icon" v-bind:class="{'selected' : rating.overall >= 3}" @click="rating.overall = 3">★</button>
                                                <button class="icon" v-bind:class="{'selected' : rating.overall >= 2}" @click="rating.overall = 2">★</button>
                                                <button class="icon" v-bind:class="{'selected' : rating.overall >= 1}" @click="rating.overall = 1">★</button>
                                            </div>

                                        </li>
                                        <li>
                                            <div class="rating-label">{{$t("rating.website")}}</div>
                                            <div class="rating">
                                                <button class="icon" v-bind:class="{'selected' : rating.website == 5}" @click="rating.website = 5">★</button>
                                                <button class="icon" v-bind:class="{'selected' : rating.website >= 4}" @click="rating.website = 4">★</button>
                                                <button class="icon" v-bind:class="{'selected' : rating.website >= 3}" @click="rating.website = 3">★</button>
                                                <button class="icon" v-bind:class="{'selected' : rating.website >= 2}" @click="rating.website = 2">★</button>
                                                <button class="icon" v-bind:class="{'selected' : rating.website >= 1}" @click="rating.website = 1">★</button>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="rating-label">{{$t("rating.callCenter")}}</div>
                                            <div class="rating">
                                                <button class="icon" v-bind:class="{'selected' : rating.service == 5}" @click="rating.service = 5">★</button>
                                                <button class="icon" v-bind:class="{'selected' : rating.service >= 4}" @click="rating.service = 4">★</button>
                                                <button class="icon" v-bind:class="{'selected' : rating.service >= 3}" @click="rating.service = 3">★</button>
                                                <button class="icon" v-bind:class="{'selected' : rating.service >= 2}" @click="rating.service = 2">★</button>
                                                <button class="icon" v-bind:class="{'selected' : rating.service >= 1}" @click="rating.service = 1">★</button>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="rating-label">{{$t("rating.price")}}</div>
                                            <div class="rating">
                                                <button class="icon" v-bind:class="{'selected' : rating.price == 5}" @click="rating.price = 5">★</button>
                                                <button class="icon" v-bind:class="{'selected' : rating.price >= 4}" @click="rating.price = 4">★</button>
                                                <button class="icon" v-bind:class="{'selected' : rating.price >= 3}" @click="rating.price = 3">★</button>
                                                <button class="icon" v-bind:class="{'selected' : rating.price >= 2}" @click="rating.price = 2">★</button>
                                                <button class="icon" v-bind:class="{'selected' : rating.price >= 1}" @click="rating.price = 1">★</button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="fb-user-info" v-if="false">
                                <div class="fb-photo">
                                    <img src="https://graph.facebook.com/1169627889754248/picture?type=square" />
                                </div>
                                <div class="fb-name">
                                    <div id="user-name">Facebook Name</div>
                                    <button class="btn btn-sm btn-signout" type="button">{{$t("fblogout")}}</button>
                                </div>
                            </div>

                            <div class="fb-user-login">
                                <button class="btn btn-sm btn-facebook fb-login-button"
                                    type="button"
                                    id="fb-login-btn"
                                    data-max-rows="1"
                                    data-size="medium"
                                    data-button-type="login_with"
                                    data-show-faces="false"
                                    data-auto-logout-link="true"
                                    data-use-continue-as="false">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M22.675 0H1.325C.593 0 0 .593 0 1.325v21.351C0 23.407.593 24 1.325 24H12.82v-9.294H9.692v-3.622h3.128V8.413c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12V24h6.116c.73 0 1.323-.593 1.323-1.325V1.325C24 .593 23.407 0 22.675 0z"  fill="#4267B2"/></svg>
                                    {{$t("fblogin")}}
                                </button>
                            </div>

                            <div class="user-comment mt-2">
                                <textarea class="form-control" rows="5"></textarea>
                                <div class="text-center my-4">
                                    <button id="make2review" class="btn  btn-lg btn-share" type="button">{{$t("submit")}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import Mixin from '@/components/Mixin';
import Global from "@/store/global.js";

export default {
  name: 'review',
  mixins: [Mixin],
  data(){
      return {
          Global,
          rating : {
              overall : 0,
              website : 0,
              service : 0,
              price : 0
          }
      }
  },
  mounted() {
    var vm = this;
    window.scrollTo(0, 0);
    //Global.quote.Start_date = "11/06/2021";
  },
  methods: {
      gobackhome(){
          location.reload()
      }
  },
  computed:{
    policyStartDate:function(){
        if( !Global.quote.Start_date ){
            return {d:'', m: '', y: ''};
        }

        var dmy =  Global.quote.Start_date.replace(/[\/\-]/g, '-').split('-');
        if( !dmy || dmy.length != 3 ){
            return {d:'', m: '', y: ''};
        }

        return {d:dmy[0], m: dmy[1], y: dmy[2]};
    }
  }
}
</script>
<i18n>
{
  "en": {
    "paymentConfirm": "Your payment has been confirmed.",
    "policyStart": "Thank you for your interest in Roojai! Your policy will be start once your payment is confirmed. Roojai will send you an email for your policy details. ",
    "policyStartPA" : "Thank you for your interest in Roojai! Your policy will start a day after your payment is confirmed. Roojai will send you an email for your policy details.",
    "shareExpirience": "Share your experience",
    "feedback": "Your feedback",
    "fblogin": "Login with Facebook",
    "fblogout": "Logout",
    "gobackhome": "Back to Home",
    "rating": {
        "overall": "Overall rating",
        "website": "Website",
        "callCenter": "Customer service",
        "price": "Product and price"
    },
    "thankForFeedback": "Thank you for your feedback.",
    "submit": "Submit",
    "policyStart_PA_installment": "Thank you for your payment. You are almost done, just one more step. Roojai call centre will contact you to confirm the policy details within 24 hours."
  },
  "id": {
    "paymentConfirm": "Pembayaran sudah di konfirmasi.",
    "policyStart": "Terimakasih atas ketertarikanmu kepada Roojai! Polis kamu akan dimulai saat pembayaranmu dikonfirmasi. Roojai akan mengirimkan email terkait detail polis.",
    "policyStartPA": "Terimakasih atas ketertarikanmu kepada Roojai! Polis kamu akan dimulai sehari setelah pembayaranmu dikonfirmasi. Roojai akan mengirimkan email terkait detail polis.",
    "shareExpirience": "Bagikan pengalaman kamu",
    "feedback": "Ulasan kamu",
    "fblogin": "Masuk dengan Facebook",
    "fblogout": "Keluar",
    "gobackhome": "Kembali ke Halaman Awal",
    "rating": {
        "overall": "Penilaian keseluruhan",
        "website": "Website",
        "callCenter": "Pelayanan pelanggan",
        "price": "Produk dan harga"
    },
    "thankForFeedback": "Terimakasih atas masukan yang diberikan.",
    "submit": "Kirim",
    "policyStart_PA_installment": "Terimakasih atas pembayaran yang dilakukan. Kamu hampir selesai dalam satu langkah lagi. Roojai call centre akan menghubungi kamu untuk mengonfirmasi terkait detail polis dalam waktu 24 jam."
  },
}
</i18n>